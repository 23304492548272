<template>
  <v-form ref="form" v-model="valid" class="my-4 pa-5" lazy-validation>
    <v-row no-gutters class="mt-2 mb-2">
      <v-col cols="12">
        <h1 class="card-container-title mb-2 d-flex align-center">
          Configuração do APP
        </h1>
      </v-col>
      <v-col class="mt-1" cols="5">
        <p class="card-container-description ma-0">
          Aqui é onde configuramos todas as opções do aplicativo da conta. Para visualizar a lista de lojas associadas,
          <a @click="openUnitList()"><b>clique aqui</b></a
          >. O nome do banco de dados do cliente é <b>{{ dbName }}</b
          >. Para publicar os aplicativos, <a href="https://mercashop.mercafacil.com/admin/"><b>clique aqui</b></a
          >.
        </p>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" focusable>
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span> Geral</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-6">
            <v-col cols="6">
              <mf-text-input v-model="app_name" :rules="[stringRules, charactersLimit30Rules]" :disabled="true" outlined label="APP_NAME" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="account_id" :disabled="true" outlined label="ACCOUNT_ID" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <mf-text-input v-model="store.name" :disabled="!editable" outlined label="NOME" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="facebook.app_id" :disabled="!editable" outlined label="FACEBOOK APP_ID" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="facebook.client_token" :disabled="!editable" outlined label="FACEBOOK CLIENT_TOKEN" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="facebook.bundle" :disabled="!editable" outlined label="FACEBOOK BUNDLE" />
            </v-col>
            <v-col v-if="ecommerce_v2.active || club.web.active" cols="12">
              <v-row no-gutters>
                <v-col>
                  <mf-text-input
                    v-model="old_ecommerce_url"
                    :rules="[validationValue]"
                    :disabled="!editable"
                    outlined
                    label="URL - Subdominio Backoffice Varejista"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-combobox
                    v-model="new_ecommerce_urls"
                    dense
                    multiple
                    :disabled="!editable"
                    label="URLS - Novo Ecommerce"
                    append-icon
                    chips
                    deletable-chips
                    outlined
                    :rules="[validateDomain]"
                    hint="Aperte ENTER ou TAB para ir adicionando as urls"
                    @input="handleInput"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="updateAppGeneral" @cancel="cancelAndRestore" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span> Cupom Verde </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-6">
            <v-col cols="6">
              <mf-text-input v-model="greenCoupon.url" :disabled="!editable" outlined label="URL" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="greenCoupon.apiKey" :disabled="!editable" outlined label="API KEY" />
            </v-col>
            <v-col cols="4" class="ml-6">
              <v-switch v-model="greenCoupon.active" label="Ativar Cupom Verde" :disabled="!editable" outlined></v-switch>
            </v-col>
          </v-row>
          <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="updateAppGreenCoupon" @cancel="cancelAndRestore" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span> Modelo do aplicativo </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-6">
            <v-col cols="6" class="mt-3">
              <mf-text-input :value="appModel" disabled label="Modelo do aplicativo"></mf-text-input>
            </v-col>
            <v-col cols="2">
              <v-row no-gutters class="mt-n2">
                <v-col cols="12" class="mt-4">
                  <span class="font-weight-bold">Modelo de aplicativo</span>
                </v-col>
                <v-col class="mx-3">
                  <v-checkbox v-model="club.active" :disabled="!editable" label="Club" color="#334A58" value />
                  <v-row no-gutters class="mt-n5 ml-5">
                    <v-col cols="12">
                      <v-checkbox v-model="club.web.active" :disabled="!editable" label="Web" color="#334A58" value @change="handlerCheckboxWeb('club')" />
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                      <v-checkbox v-model="club.app.active" :disabled="!editable" label="App" color="#334A58" value @change="handlerCheckboxApp('club')" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-checkbox v-model="ecommerce_v2.active" :disabled="!editable" label="Ecommerce" color="#334A58" value />
                  <v-row no-gutters class="mt-n5 ml-5">
                    <v-col cols="12">
                      <v-checkbox
                        v-model="ecommerce_v2.web.active"
                        :disabled="!editable"
                        label="Web"
                        color="#334A58"
                        value
                        @change="handlerCheckboxWeb('ecommerce_v2')"
                      />
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                      <v-checkbox
                        v-model="ecommerce_v2.app.active"
                        :disabled="!editable"
                        label="App"
                        color="#334A58"
                        value
                        @change="handlerCheckboxApp('ecommerce_v2')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="updateAppType" @cancel="cancelAndRestore" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span> Android & IOS Setup</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="6">
              <v-card-title><v-icon>mdi-apple</v-icon>IOS</v-card-title>
              <mf-text-input v-model="store.ios_bundle_id" disabled outlined label="APP_BUNDLE_ID_IOS" />
              <mf-text-input v-model="store.ios_build_code" :disabled="!editable" outlined label="APP_BUILD_CODE_IOS" />
              <mf-text-input v-model="store.ios_version_code" :rules="[stringRules]" :disabled="!editable" outlined label="APP_VERSION_CODE_IOS" />
              <mf-text-input v-model="codepush.deployment_key_ios" :disabled="!editable" outlined label="CODE_PUSH_DEPLOYMENT_KEY_IOS" />
              <mf-text-input v-model="store.release_tag_ios" :disabled="!editable" outlined label="RELEASE_TAG_IOS" />
              <v-row class="switch-container">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="1" v-on="on">
                      <mf-toggle v-model="apple.release_mode" :disabled="!editable" color="#334A58" label="TYPE_RELEASE_IOS" />
                    </v-col>
                  </template>
                  <span>Será automaticamente liberado na Apple assim que aprovado.</span>
                </v-tooltip>
              </v-row>
              <v-row class="switch-container">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="1" v-on="on">
                      <mf-toggle v-model="apple.type_submit_review_ios" :disabled="!editable" color="#334A58" label="TYPE_SUBMIT_REVIEW_IOS" />
                    </v-col>
                  </template>
                  <span>Será enviado automaticamente para avaliação da Apple.</span>
                </v-tooltip>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-card-title><v-icon>android</v-icon>ANDROID</v-card-title>
              <mf-text-input v-model="store.android_bundle_id" disabled outlined label="APP_BUNDLE_ID_ANDROID" />
              <mf-text-input v-model="store.android_build_code" :rules="[stringRules]" :disabled="!editable" outlined label="APP_BUILD_CODE_ANDROID" />
              <mf-text-input v-model="store.android_version_code" :disabled="!editable" outlined label="APP_VERSION_CODE_ANDROID" />
              <mf-text-input v-model="codepush.deployment_key_android" :disabled="!editable" outlined label="CODE_PUSH_DEPLOYMENT_KEY_ANDROID" />
              <mf-text-input v-model="store.release_tag_android" :disabled="!editable" outlined label="RELEASE_TAG_ANDROID" />
              <v-row class="switch-container">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="1" v-on="on">
                      <mf-toggle v-model="android.type_release" :disabled="!editable" color="#334A58" label="TYPE_RELEASE_ANDROID" />
                    </v-col>
                  </template>
                  <span>Se verdadeiro, será lançado para produção; se falso, será lançado para o ambiente interno.</span>
                </v-tooltip>
              </v-row>
              <v-row class="switch-container">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="1" v-on="on">
                      <mf-toggle v-model="codepush.force_install" :disabled="!editable" color="#334A58" label="FORCE_INSTALL" />
                    </v-col>
                  </template>
                  <span>As atualizações de codepush serão feitas automaticamente ou somente com permissão do usuário.</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="updateAppStoreCodepush" @cancel="cancelAndRestore" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="appModel === 'spider-man'" class="mt-3">
        <v-expansion-panel-header>
          <span>Cores Aplicativo</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>BACKGROUND</v-card-title>
              <color-picker
                :rules="[stringRules, hexColorRule, app_colors.primary !== app_colors.primary_text || 'PRIMARY não pode ser igual a PRIMARY_TEXT']"
                label="PRIMARY"
                :color.sync="app_colors.primary"
                :contrast-text-color="app_colors.primary_text"
                :edit="editable"
              />
              <color-picker
                :rules="[stringRules, hexColorRule, app_colors.secondary !== app_colors.secondary_text || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
                label="SECONDARY"
                :color.sync="app_colors.secondary"
                :contrast-text-color="app_colors.secondary_text"
                :edit="editable"
              />
            </v-col>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>LIGHT</v-card-title>
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.primary_light !== app_colors.primary_text_light || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
                ]"
                label="PRIMARY_LIGHT"
                :color.sync="app_colors.primary_light"
                :contrast-text-color="app_colors.primary_text_light"
                :edit="editable"
              />
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.secondary_light !== app_colors.secondary_text_light || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
                ]"
                label="SECONDARY_LIGHT"
                :color.sync="app_colors.secondary_light"
                :contrast-text-color="app_colors.secondary_text_light"
                :edit="editable"
              />
            </v-col>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>DARK</v-card-title>
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.primary_dark !== app_colors.primary_text_dark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
                ]"
                label="PRIMARY_DARK"
                :color.sync="app_colors.primary_dark"
                :contrast-text-color="app_colors.primary_text_dark"
                :edit="editable"
              />
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.secondary_dark !== app_colors.secondary_text_dark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
                ]"
                label="SECONDARY_DARK"
                :color.sync="app_colors.secondary_dark"
                :contrast-text-color="app_colors.secondary_text_dark"
                :edit="editable"
              />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>TEXT</v-card-title>
              <color-picker
                :rules="[stringRules, hexColorRule, app_colors.primary !== app_colors.primary_text || 'PRIMARY não pode ser igual a PRIMARY_TEXT']"
                label="PRIMARY_TEXT"
                :color.sync="app_colors.primary_text"
                :edit="editable"
              />
              <color-picker
                :rules="[stringRules, hexColorRule, app_colors.secondary !== app_colors.secondary_text || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
                label="SECONDARY_TEXT"
                :color.sync="app_colors.secondary_text"
                :edit="editable"
              />
            </v-col>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>LIGHT</v-card-title>
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.primary_light !== app_colors.primary_text_light || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
                ]"
                label="PRIMARY_TEXT_LIGHT"
                :color.sync="app_colors.primary_text_light"
                :edit="editable"
              />
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.secondary_light !== app_colors.secondary_text_light || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
                ]"
                label="SECONDARY_TEXT_LIGHT"
                :color.sync="app_colors.secondary_text_light"
                :edit="editable"
              />
            </v-col>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>DARK</v-card-title>
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.primary_dark !== app_colors.primary_text_dark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
                ]"
                label="PRIMARY_TEXT_DARK"
                :color.sync="app_colors.primary_text_dark"
                :edit="editable"
              />
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  app_colors.secondary_dark !== app_colors.secondary_text_dark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
                ]"
                label="SECONDARY_TEXT_DARK"
                :color.sync="app_colors.secondary_text_dark"
                :edit="editable"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>SPLASH</v-card-title>
              <color-picker :rules="[stringRules, hexColorRule]" label="SPLASH" :color.sync="app_colors.splash" :edit="editable" />
            </v-col>
          </v-row>

          <v-col v-if="app_colors.composition.general" class="pa-0">
            <v-row no-gutters>
              <v-card-title>
                Composição geral
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" class="ml-1" v-on="on">
                      <v-icon small>info</v-icon>
                    </div>
                  </template>
                  <span>Este campo reflete na identidade visual do site, mas não afeta os componentes listados abaixo (Composição por componente).</span>
                </v-tooltip>
              </v-card-title>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <v-select
                  v-model="app_colors.composition.general"
                  :disabled="!editable"
                  label="Composição geral"
                  outlined
                  :items="composition_color_items"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="app_colors.composition.components.length" class="pa-0">
            <v-row no-gutters>
              <v-card-title>Composição por componente</v-card-title>
            </v-row>
            <v-row no-gutters>
              <v-col v-for="component in app_colors.composition.components" :key="component.name" cols="4">
                <v-select v-model="component.value" :disabled="!editable" :label="component.name" outlined :items="composition_color_items"></v-select>
              </v-col>
            </v-row>
          </v-col>

          <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="updateAppColors" @cancel="cancelAndRestore(true)" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-else class="mt-3">
        <v-expansion-panel-header>
          <span>Cores Aplicativo</span>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>SPLASH & NAV</v-card-title>
              <color-picker
                :rules="[stringRules, hexColorRule, old_app_colors.nav !== old_app_colors.nav_text || 'NAV não pode ser igual a NAV_TEXT']"
                :edit="editable"
                label="NAV"
                :color.sync="old_app_colors.nav"
              />
              <color-picker
                :rules="[stringRules, hexColorRule, old_app_colors.nav_text !== old_app_colors.nav || 'NAV_TEXT não pode ser igual a NAV']"
                :edit="editable"
                label="NAV_TEXT"
                :color.sync="old_app_colors.nav_text"
              />
              <color-picker :rules="[stringRules, hexColorRule]" :edit="editable" label="SPLASH" :color.sync="old_app_colors.splash" />
            </v-col>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>MAIN COLOR</v-card-title>
              <color-picker
                :rules="[stringRules, hexColorRule, old_app_colors.main !== old_app_colors.main_text || 'MAIN não pode ser igual a MAIN_TEXT']"
                :edit="editable"
                label="MAIN"
                :color.sync="old_app_colors.main"
                :contrast-text-color="old_app_colors.main_text"
              />
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  old_app_colors.secondary !== old_app_colors.secondary_text || 'SECONDARY não pode ser igual a SECONDARY_TEXT'
                ]"
                :edit="editable"
                label="SECONDARY"
                :color.sync="old_app_colors.secondary"
                :contrast-text-color="old_app_colors.secondary_text"
              />
            </v-col>
            <v-col cols="4">
              <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>TEXT COLOR</v-card-title>
              <color-picker
                :rules="[stringRules, hexColorRule, old_app_colors.main_text !== old_app_colors.main || 'MAIN_TEXT não pode ser igual a MAIN']"
                :edit="editable"
                label="MAIN_TEXT"
                :color.sync="old_app_colors.main_text"
              />
              <color-picker
                :rules="[
                  stringRules,
                  hexColorRule,
                  old_app_colors.secondary_text !== old_app_colors.secondary || 'SECONDARY_TEXT não pode ser igual a SECONDARY'
                ]"
                :edit="editable"
                label="SECONDARY_TEXT"
                :color.sync="old_app_colors.secondary_text"
              />
            </v-col>
          </v-row>
          <btn-card-actions class="pb-0" :editable.sync="editable" :saving="saving" @save="updateAppColors" @cancel="cancelAndRestore(true)" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span>Apple Account Data</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-6">
            <v-col cols="6">
              <mf-text-input v-model="apple_account_data.team_name" :rules="[stringRules]" :disabled="!editable" outlined label="TEAM_NAME" />
              <mf-text-input v-model="apple_account_data.team_id" :rules="[stringRules]" :disabled="!editable" outlined label="TEAM_ID" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="apple_account_data.itc_team_id" :rules="[stringRules]" :disabled="!editable" outlined label="ITC_TEAM_ID" />
              <mf-text-input
                v-model="apple_account_data.fastlane_apple_application_specific_password"
                :rules="[stringRules]"
                :disabled="!editable"
                outlined
                label="FASTLANE_APPLE_APPLICATION_SPECIFIC_PASSWORD"
              />
            </v-col>
            <v-col cols="12">
              <v-jsoneditor v-model="apple_key_json" class="px-4" :plus="false" height="400p'" width="400px" :options="options" />
            </v-col>
          </v-row>
          <btn-card-actions
            class="pb-0"
            :saving="saving"
            :editable.sync="editable"
            @update:editable="options.mode = 'code'"
            @save="updateAppleTeam"
            @cancel="cancelAndRestore()"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span>Android Account Data</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-6">
            <v-col cols="12">
              <v-jsoneditor v-model="android_key_json" class="px-4" :plus="false" height="400p'" width="400px" :options="options" />
            </v-col>
          </v-row>
          <btn-card-actions
            class="pb-0"
            :saving="saving"
            :editable.sync="editable"
            @update:editable="options.mode = 'code'"
            @save="updateAndroidTeam"
            @cancel="cancelAndRestore()"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>
          <span>Keystore</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-6">
            <v-col cols="6">
              <mf-text-input v-model="keystore.key_alias" :disabled="!editable" outlined label="Key Alias" />
              <mf-text-input v-model="keystore.key_password" :disabled="!editable" outlined label="Key Password" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="keystore.store_file" :disabled="!editable" outlined label="Store File" />
              <mf-text-input v-model="keystore.store_password" :disabled="!editable" outlined label="Store Password" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <btn-card-actions class="pb-0" :editable.sync="editable" @save="dialog = true" @cancel="cancelAndRestore()" />
            <v-spacer />
            <mf-button icon="upload" class="mt-4 mr-4" outlined label="Upload Keystore" @click="uploadFile = true" />
            <mf-button
              icon="download"
              label="Keystore"
              class="mt-4 mr-4"
              :disabled="!android_links.keystore_link"
              outlined
              @click="openLink(android_links.keystore_link)"
            />
            <mf-button label=".AAB" icon="download" class="mt-4 mr-4" :disabled="!android_links.aab_link" outlined @click="openLink(android_links.aab_link)" />
            >
          </v-row>

          <upload-file-modal :dialog="uploadFile" :account-id="accountId" :app-name="app.app_name" @close="closeUpload" />
          <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @cancel="closeDialog" @proceed="updateAppKeystore" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import { readableColor } from 'polished'
import { MUTATION_UPDATE_APP_PROPS, MUTATION_UPDATE_GREEN_COUPON, QUERY_CONFIGURATION } from '@/modules/accounts/graphql'
import { deepDelete } from '@/helpers/deepDelete'
import { debounceTime } from '@/helpers/debounceTime'
import { genHexLightDark } from '@/helpers/genHexLightDark'
import { appDefault } from '@/modules/accounts/assets/app/index.js'
import hexColorRule from '../../mixins/colors'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions.vue'),
    ColorPicker: () => import('@/components/colors/ColorPicker.vue'),
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog'),
    UploadFileModal: () => import('@/modules/accounts/components/app/UploadFileModal')
  },
  mixins: [hexColorRule],
  props: {
    app: {
      type: Object,
      default: () => ({})
    },
    accountId: {
      type: String,
      default: () => ''
    },
    clientName: {
      type: String,
      default: () => ''
    },
    dbName: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    options: { mode: 'view', mainMenuBar: false },
    panel: [],
    app_model: '',
    app_type: '',
    saving: false,
    club: {
      active: false,
      app: {
        active: false
      },
      web: {
        active: false
      }
    },
    ecommerce_v2: {
      active: false,
      app: {
        active: false
      },
      web: {
        active: false
      }
    },
    greenCoupon: {
      url: '',
      apiKey: '',
      activate: false
    },
    old_app_colors: appDefault.old_colors,
    app_colors: {
      ...appDefault.colors,
      splash: appDefault.old_colors.splash,
      composition: {
        general: '',
        components: []
      }
    },
    composition_color_items: ['PRIMARY', 'SECONDARY', 'MINIMAL'],
    old_ecommerce_url: '',
    new_ecommerce_urls: [],
    app_model_items: ['octopus', 'spider-man'],
    valid: false,
    dialog: false,
    uploadFile: false,
    textDialog: 'Tem certeza que deseja alterar as informações da Key Store? Essa operação é irreversivel e pode causar a perda do aplicativo.',
    numberRules: v => !isNaN(+v) || 'Valor precisa ser numérico',
    specialCharactersRules: v => !/[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/.test(v) || 'Campo não pode conter caractéres especiais',
    charactersLimit30Rules: v => (v || '').length <= 30 || 'Campo não pode ter mais que 30 caracteres',
    charactersLimit7Rules: v => (v || '').length <= 7 || 'Campo não pode ter mais que 7 caracteres',
    charactersLimit9Rules: v => (v || '').length <= 9 || 'Campo não pode ter mais que 9 caracteres',
    charactersLimit100Rules: v => (v || '').length <= 100 || 'Campo não pode ter mais que 100 caracteres',
    stringRules: v => !!v || 'Campo não pode ser nulo',
    editable: false,
    app_name: '',
    account_id: '',
    facebook: {
      app_id: '',
      client_token: '',
      bundle: ''
    },
    store: {
      ios_version_code: '',
      ios_bundle_id: '',
      ios_build_code: '',
      android_version_code: '',
      android_bundle_id: '',
      android_build_code: '',
      release_tag_ios: '',
      release_tag_android: '',
      name: ''
    },
    codepush: {
      deployment_key_ios: '',
      deployment_key_android: ''
    },
    apple: {
      release_mode: false,
      type_submit_review_ios: ''
    },
    android: {
      type_release: false
    },
    apple_account_data: {
      fastlane_apple_application_specific_password: '',
      itc_team_id: '',
      match_keychain_password: '',
      team_id: '',
      team_name: ''
    },
    android_key_json: {},
    apple_key_json: {},
    keystore: {
      key_alias: '',
      key_password: '',
      store_file: '',
      store_password: ''
    },
    android_links: { keystore_link: '', aab_link: '' }
  }),
  computed: {
    appModel() {
      return ['phygital', 'ecommerce', 'club'].includes(this.appType) ? 'spider-man' : 'octopus'
    },
    appType() {
      let res =
        this.club.active && this.ecommerce_v2.active
          ? 'phygital'
          : !this.club.active && this.ecommerce_v2.active
          ? 'ecommerce'
          : this.club.active && !this.ecommerce_v2.active
          ? 'club'
          : ''
      return res
    }
  },
  apollo: {
    configuration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      update(data) {
        const config = data.configuration.green_coupon
        this.greenCoupon.active = config?.active
        this.greenCoupon.url = config?.integration?.url
        this.greenCoupon.apiKey = config?.integration?.api_key
      },
      error(error) {
        console.error('Erro ao buscar a configuração:', error)
      }
    }
  },
  watch: {
    'old_app_colors.main': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          this.old_app_colors.main_text = this.customReadableColor(color)
        })
      }
    },
    'old_app_colors.secondary': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          this.old_app_colors.secondary_text = this.customReadableColor(color)
        })
      }
    },
    'app_colors.primary': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          const [baseLight, baseDark] = genHexLightDark(color)

          this.app_colors = {
            ...this.app_colors,
            primary_light: baseLight,
            primary_dark: baseDark,
            primary_text: this.customReadableColor(color),
            primary_text_light: this.customReadableColor(baseLight),
            primary_text_dark: this.customReadableColor(baseDark)
          }
        })
      }
    },
    'app_colors.primary_light': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          this.app_colors.primary_text_light = this.customReadableColor(color)
        })
      }
    },
    'app_colors.primary_dark': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          this.app_colors.primary_text_dark = this.customReadableColor(color)
        })
      }
    },
    'app_colors.secondary': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          const [baseLight, baseDark] = genHexLightDark(color)

          this.app_colors = {
            ...this.app_colors,
            secondary_light: baseLight,
            secondary_dark: baseDark,
            secondary_text: this.customReadableColor(color),
            secondary_text_light: this.customReadableColor(baseLight),
            secondary_text_dark: this.customReadableColor(baseDark)
          }
        })
      }
    },
    'app_colors.secondary_light': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          this.app_colors.secondary_text_light = this.customReadableColor(color)
        })
      }
    },
    'app_colors.secondary_dark': function(color, oldColor) {
      if (this.editable) {
        this.onAfterColorChange(color, oldColor, () => {
          this.app_colors.secondary_text_dark = this.customReadableColor(color)
        })
      }
    }
  },
  mounted() {
    this.setValues(this.app)
  },
  methods: {
    validationValue(value) {
      return this.new_ecommerce_urls?.length > 0 && this.new_ecommerce_urls.includes(this.extractDomain(value))
        ? 'Este campo não pode ser igual a qualquer URL em URLS - Novo Ecommerce.'
        : true
    },
    extractDomain(url) {
      const domain = url
        .replace(/(https?:\/\/)?(www\.)?/i, '')
        .split('/')[0]
        .split(':')[0]
        .split('?')[0]
      return domain
    },
    validateItem(item) {
      const domain = this.extractDomain(this.old_ecommerce_url)
      return item !== domain
    },
    handleInput(value) {
      const newItem = value[value.length - 1]
      if (this.validateItem(newItem)) {
        this.new_ecommerce_urls = value
      } else {
        this.new_ecommerce_urls.pop()
      }
    },
    validateDomain(value) {
      const domainPattern = /^(?!:\/\/)(?!www\.)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/
      const hasAnInvalidDomain = value.find(domain => {
        const trimmedDomain = domain.trim()
        return !domainPattern.test(trimmedDomain) || trimmedDomain.includes(' ')
      })
      if (hasAnInvalidDomain) {
        return 'Apenas domínios são permitidos, e não deve haver espaços.'
      }
      return true
    },
    openUnitList() {
      window.open(this.$router.resolve(`/accounts/${this.accountId}/edit/lojas`).href, '_blank')
    },
    handlerCheckboxApp(type) {
      if (this[type].app.active) this[type].active = true
    },
    handlerCheckboxWeb(type) {
      if (this[type].web.active) this[type].active = true
    },
    closeDialog() {
      this.dialog = false
    },
    onAfterColorChange(color, oldColor, cb) {
      if (color && oldColor && color !== oldColor) {
        debounceTime(() => {
          return cb()
        }, 400)
      }
    },
    customReadableColor(color) {
      return readableColor(color, '#000000', '#FFFFFF')
    },
    setValues(app) {
      this.app_name = app?.app_name
      this.account_id = app?.account_id
      this.facebook.app_id = app?.facebook?.app_id
      this.facebook.client_token = app?.facebook?.client_token
      this.facebook.bundle = app?.facebook?.bundle
      this.app_type = app?.app_type || 'default'
      this.app_model = this.app_type === 'default' ? 'octopus' : 'spider-man'
      this.club = {
        active: app?.club?.active,
        app: {
          active: app?.club?.app?.active
        },
        web: {
          active: app?.club?.web?.active
        }
      }
      this.ecommerce_v2 = {
        active: app?.ecommerce_v2?.active,
        app: {
          active: app?.ecommerce_v2?.app?.active
        },
        web: {
          active: app?.ecommerce_v2?.web?.active
        }
      }
      this.old_ecommerce_url = app.ecommerce_v2?.integration?.url
      this.new_ecommerce_urls = app?.consumer_app?.urls
      this.store.ios_version_code = app?.store?.ios_version_code
      this.store.ios_bundle_id = app?.store?.ios_bundle_id
      this.store.ios_build_code = app?.store?.ios_build_code
      this.store.android_version_code = app?.store?.android_version_code
      this.store.android_bundle_id = app?.store?.android_bundle_id
      this.store.android_build_code = app?.store?.android_build_code
      this.store.name = app?.store?.name
      this.store.release_tag_android = app?.store?.release_tag_android || ''
      this.store.release_tag_ios = app?.store?.release_tag_ios || ''
      this.codepush.deployment_key_ios = app?.codepush?.deployment_key_ios
      this.codepush.deployment_key_android = app?.codepush?.deployment_key_android
      this.codepush.force_install = app?.codepush?.force_install
      this.apple.release_mode = app?.apple?.release_mode
      this.apple.type_submit_review_ios = app?.apple?.type_submit_review_ios
      this.android.type_release = app?.android?.type_release === 'production'
      this.apple_account_data.fastlane_apple_application_specific_password = app?.apple_account_data?.fastlane_apple_application_specific_password
      this.apple_account_data.itc_team_id = app?.apple_account_data?.itc_team_id
      this.apple_account_data.match_keychain_password = app?.apple_account_data?.match_keychain_password
      this.apple_account_data.team_id = app?.apple_account_data?.team_id
      this.apple_account_data.team_name = app?.apple_account_data?.team_name

      this.setAppColors(app?.colors)

      if (app?.metadata?.android?.key_json) {
        deepDelete(app?.metadata?.android?.key_json, ['__typename'])
        this.android_key_json = app?.metadata?.android?.key_json
      } else {
        this.android_key_json = {}
      }
      if (app?.metadata?.ios?.key_json) {
        deepDelete(app?.metadata?.ios?.key_json, ['__typename'])
        this.apple_key_json = app?.metadata?.ios?.key_json
      } else {
        this.apple_key_json = {}
      }
      this.keystore.key_alias = app?.keystore?.key_alias
      this.keystore.key_password = app?.keystore?.key_password
      this.keystore.store_file = app?.keystore?.store_file
      this.keystore.store_password = app?.keystore?.store_password

      this.android_links.keystore_link = app?.metadata?.android?.keystore_link
      this.android_links.aab_link = app?.metadata?.android?.aab_link
    },
    setOldAppColors(old_colors) {
      this.old_app_colors = old_colors
    },
    setAppColors(app_colors) {
      const { __typename, composition, ...colors } = app_colors

      if (this.appModel === 'spider-man') {
        const { composition: compositionDefault, ...defaultColors } = this.app_colors

        const parsedColors = Object.keys(defaultColors).reduce((acc, item) => {
          const value = colors[item] === null ? defaultColors[item] : colors[item]

          return { ...acc, [item]: value }
        }, {})

        const parsedComponents = composition.components.map(({ __typename, ...rest }) => rest)

        this.app_colors = {
          ...parsedColors,
          splash: colors.splash,
          composition: {
            general: composition.general ?? '',
            components: parsedComponents
          }
        }
      } else {
        const parsedColorss = Object.keys(this.old_app_colors).reduce((acc, item) => {
          return { ...acc, [item]: colors[item] }
        }, {})

        this.setOldAppColors(parsedColorss)
      }
    },
    cancelAndRestore(hardRestore) {
      this.editable = false
      this.options.mode = 'view'
      if (hardRestore) this.$emit('refresh')
      this.setValues(this.app)
    },
    closeUpload() {
      this.uploadFile = false
      this.cancelAndRestore(true)
    },
    async updateAppGeneral() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const update = {
        channels: {
          app: {
            build: {
              app_name: this.app_name,
              account_id: this.account_id,
              facebook: {
                app_id: this.facebook.app_id,
                client_token: this.facebook.client_token,
                bundle: this.facebook.bundle
              }
            }
          }
        }
      }

      if (this.ecommerce_v2.active || this.club.web.active) {
        update.channels.ecommerce_v2 = {
          integration: {
            url: this.old_ecommerce_url
          }
        }

        update.channels.consumer_app = {
          urls: this.new_ecommerce_urls
        }
      }

      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err?.message)

        const { readableError } = err?.graphQLErrors[0]?.extensions?.exception?.response?.response ?? {}

        this.$alert({
          alert_message: readableError || `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAppType() {
      const update = {
        channels: {
          club: this.club,
          ecommerce_v2: this.ecommerce_v2
        }
      }
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err?.message)
        this.$alert({
          alert_message: `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAppGreenCoupon() {
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_GREEN_COUPON,
          variables: {
            accountId: this.accountId,
            input: {
              active: this.greenCoupon.active,
              integration: {
                url: this.greenCoupon.url,
                apiKey: this.greenCoupon.apiKey
              }
            }
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err?.message)

        const { readableError } = err?.graphQLErrors[0]?.extensions?.exception?.response?.response ?? {}

        this.$alert({
          alert_message: readableError || `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAppStoreCodepush() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const update = {
        channels: {
          app: {
            build: {
              store: {
                ios_bundle_id: this.store.ios_bundle_id,
                android_bundle_id: this.store.android_bundle_id,
                ios_version_code: this.store.ios_version_code.toString(),
                ios_build_code: this.store.ios_build_code,
                android_build_code: this.store.android_build_code.toString(),
                android_version_code: this.store.android_version_code
              },
              codepush: {
                deployment_key_ios: this.codepush.deployment_key_ios,
                deployment_key_android: this.codepush.deployment_key_android,
                force_install: this.codepush.force_install
              },
              apple: {
                release_mode: this.apple.release_mode,
                type_submit_review_ios: this.apple.type_submit_review_ios
              },
              android: {
                type_release: this.android.type_release ? 'production' : 'internal'
              }
            }
          }
        }
      }
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err?.message)
        this.$alert({
          alert_message: `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAppColors() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const update = {
        channels: {
          app: {
            build: {
              colors:
                this.appModel === 'spider-man'
                  ? { main: this.app_colors.primary, main_text: this.app_colors.primary_text, ...this.app_colors }
                  : this.old_app_colors
            }
          }
        }
      }

      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAppleTeam() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const update = {
        channels: {
          app: {
            build: {
              apple_account_data: {
                fastlane_apple_application_specific_password: this.apple_account_data.fastlane_apple_application_specific_password,
                itc_team_id: this.apple_account_data.itc_team_id,
                team_id: this.apple_account_data.team_id,
                team_name: this.apple_account_data.team_name
              },
              metadata: {
                ios: {
                  key_json: this.apple_key_json
                }
              }
            }
          }
        }
      }

      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAndroidTeam() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const update = {
        channels: {
          app: {
            build: {
              metadata: {
                android: {
                  key_json: this.android_key_json
                }
              }
            }
          }
        }
      }
      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    async updateAppKeystore() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const update = {
        channels: {
          app: {
            build: {
              keystore: {
                key_alias: this.keystore.key_alias,
                key_password: this.keystore.key_password,
                store_password: this.keystore.store_password,
                store_file: this.keystore.store_file
              }
            }
          }
        }
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_PROPS,
          variables: {
            account_id: this.accountId,
            update
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        this.cancelAndRestore(true)
        this.$alert({
          alert_message: `Alterações salvas com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: `Falha ao salvar alterações`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    openLink(link) {
      return window.open(link, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.ace-container {
  font-family: 'Monaco';
  @font-face {
    font-family: 'Monaco';
    src: url('https://github.com/taodongl/monaco.ttf/blob/master/monaco.ttf');
    font-weight: normal;
    font-style: normal;
  }
}
.ace-editor {
  font-family: 'Monaco';
  @font-face {
    font-family: 'Monaco';
    src: url('https://github.com/taodongl/monaco.ttf/blob/master/monaco.ttf');
    font-weight: normal;
    font-style: normal;
  }
}
.action-btn {
  margin: 2px 2px 0 6px;
}
.v-text-field {
  padding: 2px 15px;
}
.switch-container {
  padding: 2px 15px;
}
.v-icon {
  margin-right: 8px;
}
.card-container-description {
  word-break: break-word;
  font-size: 14px;
  line-height: 20px;
}
.card-container-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  flex: 1;
  color: #4b525a;
  margin: 0;
  gap: 12px;
}
</style>
